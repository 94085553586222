import React from "react";
import { SettingPageId } from "../../../utils/Constant";
import CustomWinner from "../../../components/custom_winner/CustomWinner";

export default function CustomSpin() {
  return (
    <div>
      <CustomWinner settingPageId={SettingPageId.spin}></CustomWinner>
    </div>
  );
}
