export const KeyToken = 'key'

export const UserRole = {
  admin: 1,
  operator: 2
}

export const SettingPageId = {
  bulanan: 1,
  hariRaya: 2,
  special: 3,
  spin: 4
}

export const Server = {
  //dev: 'http://localhost:3333',
  dev: 'https://api-undian-1.keblokir.com',
  prod: 'https://api-undian-1.keblokir.com',
  baseProd: ''
}
