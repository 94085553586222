import React from "react";
import { SettingPageId } from "../../../utils/Constant";
import HistoryImportComponent from "../../../components/ubo/history_import/HistoryImport";

export default function HistorySpin() {
  return (
    <div>
       <HistoryImportComponent
        id_undian={SettingPageId.spin}
      ></HistoryImportComponent>
    </div>
  );
}
