import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import { ServiceHistoryImport } from "../../../service/ServiceHistoryImport";
import { IHistoryImport } from "../../../model/History";
import { FromStringToDateTime } from "../../../utils/Helper";
import PesertaComponent from "../peserta/Peserta";

interface IHistoryImportComponent {
  id_undian: number;
}

export default function HistoryImportComponent(props: IHistoryImportComponent) {
  const ServiceHistoryImportHttp = new ServiceHistoryImport();
  const [data, setData] = useState<IHistoryImport[]>([]);
  const [selectedId, setSelectedId] = useState<number>();
  const [showModal, setShowModal] = useState(false);
  const [column] = useState([
    {
      title: "No",
      dataIndex: "index_to_show",
      sorter: (a, b) => a.index_to_show - b.index_to_show,
    },
    {
      title: "Nama Undian",
      dataIndex: "deskripsi",
      sorter: (a, b) => {
        const temp = a.deskripsi.localeCompare(b.deskripsi);
        return parseInt(temp);
      },
    },
    {
      title: "Tanggal",
      dataIndex: "tanggal_to_show",
      sorter: (a: any, b: any) => {
        const dateOne = new Date(a.tanggal_to_show);
        const dateTwo = new Date(b.tanggal_to_show);
        const result = (dateOne as any) - (dateTwo as any);
        return result;
      },
    },
    {
      title: "Detail",
      dataIndex: "Detail",
      render: (text, record) => {
        return (
          <Button
            onClick={() => {
              setSelectedId(record.id);
              setShowModal(true);
            }}
          >
            Detail
          </Button>
        );
      },
    },
    {
      title: "Hapus",
      dataIndex: "Hapus",
      render: (text, record) => {
        return (
          <Button
            onClick={() => {
              if (
                confirm(
                  "Menghapus import juga akan menghapus peserta yang bersangkutan, Apakah anda yakin ?"
                )
              ) {
                deleteHistory(record.id);
              }
            }}
          >
            Hapus
          </Button>
        );
      },
    },
  ]);

  useEffect(() => {
    getHistoryImport();
  }, []);

  async function getHistoryImport() {
    const results = await ServiceHistoryImportHttp.get({
      id_undian: props.id_undian,
    });
    results.data.map((item, index) => {
      item.index_to_show = index + 1;
      item.tanggal_to_show = FromStringToDateTime(item.created_at);
      item.detail_button = "Detail";
      return item;
    });
    setData(results.data);
  }

  async function deleteHistory(id: number) {
    const results = await ServiceHistoryImportHttp.delete({
      id,
    });
    window.location.reload();
  }

  return (
    <div>
      <Table
        columns={column as any}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{ pageSize: 10, total: data.length }}
      />
      <Modal
        width={"90%"}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => setShowModal(false)}
      >
        <PesertaComponent
          idHistoryImport={selectedId}
          id_undian={props.id_undian}
        ></PesertaComponent>
      </Modal>
    </div>
  );
}
