import { ICreateHistoryPemenang, IHistoryPemenang } from "../model/History";
import { Request } from "../utils/Http";

export class ServiceHistoryPemenang {
  public async add({
    hadiah,
    hadiah_actual,
    id_peserta,
    id_undian,
  }: IHistoryPemenang): Promise<ICreateHistoryPemenang> {
    const results = await Request({
      method: "POST",
      url: "/history/pemenang",
      showToast: false,
      data: {
        id_undian,
        id_peserta,
        hadiah,
        hadiah_actual
      },
    });
    return results;
  }
}
