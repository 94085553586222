import { Col, notification, Row } from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CustomFooter from "../../components/footer/Footer";
import CustomHeader from "../../components/header/Header";
import { IPeserta } from "../../model/Peserta";
import { ISegmentData, ISettingBulanan } from "../../model/SettingBulanan";
import { ServiceHistoryPemenang } from "../../service/ServiceHistoryPemenang";
import { ServicePeserta } from "../../service/ServicePeserta";
import { ServiceSetting } from "../../service/ServiceSetting";
import { Server, SettingPageId } from "../../utils/Constant";
import { MaskingText } from "../../utils/Helper";
import "./Spin.css";
import videojs from "video.js";
import "video.js/dist/video-js.css";

let indexPlayer = 0;
let spin = 5;
let duration = 5;
const INIT_SETTING = -1;
const INIT_STATE = 0;
const START_STATE = 1;
const STOP_STATE = 2;

// video
const play = {
  fill: true,
  fluid: true,
  autoplay: true,
  controls: true,
  preload: "metadata",
  muted: true,
  sources: [
    {
      src: "", //
      type: "application/x-mpegURL",
    },
  ],
};

const Video = (props) => {
  const videoNode = useRef<any>(null);
  const [player, setPlayer] = useState<any>(null);
  useEffect(() => {
    if (videoNode.current) {
      const _player = videojs(videoNode.current, props);
      setPlayer(_player);
      return () => {
        if (player !== null) {
          player.dispose();
        }
      };
    }
  }, []);

  return (
    <div data-vjs-player>
      <video ref={videoNode} className="video-js"></video>
    </div>
  );
};

export default function Spin() {
  const HttpPeserta = new ServicePeserta();
  const HttpHistoryPemenang = new ServiceHistoryPemenang();
  const HttpSetting = new ServiceSetting();
  const refCanvas = useRef<any>();
  const refWheel = useRef<any>();
  const refTimerPer = useRef<any>();
  const winWheelJs = require("winwheel");
  const [setting, setSetting] = useState<ISettingBulanan | null>(null);
  const [page, setPage] = useState(0);
  const [dataPeserta, setDataPeserta] = useState<IPeserta[]>([]);
  const [perTimerMinutes, setPerTimerMinutes] = useState(0); //Minutes
  const [perTimer, setPerTimer] = useState(5); //Second
  const [maxMinutes, setMaxMinutes] = useState(0);
  const [isPlay, setIsPlay] = useState(false);
  const [dataSegment, setDataSegment] = useState<ISegmentData[]>([]);
  const [prize, setPrize] = useState<{ index: number; prize: string }>();
  const [numberToSpin, setNumberToSpin] = useState<number>(INIT_SETTING);
  const [winnerName, setWinnerName] = useState("");
  const [state, setState] = useState(INIT_STATE);
  const [ctr, setCtr] = useState(0);
  const [ctrStop, strStop] = useState(0);
  const [videoSrc, setVideoSrc] = useState<any>();
  const [loadingStreaming, setLoadingStreaming] = useState(true);

  useEffect(() => {
    getSetting();
    getPeserta();
  }, []);

  useEffect(() => {
    if (isPlay && numberToSpin > 0) {
      // Wait until wheel ready
      if (setting && setting.waktuDelay) {
        setTimeout(() => {
          millisToMinutesAndSeconds(setting.waktuDelay);
        }, 1000);
        setTimeout(() => {
          startSpin(indexPlayer);
        }, 1000);
      }
    }
  }, [numberToSpin, isPlay]);

  useEffect(() => {
    if (prize) {
      const peserta = dataPeserta[prize.index];
      HttpHistoryPemenang.add({
        id_undian: SettingPageId.spin,
        id_peserta: peserta.id,
        hadiah: peserta.hadiah,
        hadiah_actual: prize.prize,
      });
      const ctrTemp = ctr + 1;
      if (ctr % 10 === 0) {
        onNextClick();
      }
      setCtr(ctrTemp);
    }
  }, [prize]);

  useEffect(() => {
    if (!refCanvas.current || refWheel.current) {
      return;
    }
    if (setting && setting.waktuDelay) {
      duration = setting.waktuDelay / 1000;
      spin = duration;
    }
    refWheel.current = new winWheelJs({
      canvasId: "myCanvas",
      numSegments: 20,
      fillStyle: "white",
      lineWidth: 0,
      innerRadius: 50,
      pointerAngle: 270,
      rotationAngle: -7,
      textDirection: "reversed",
      animation: {
        spins: spin,
        type: "spinToStop", // Type of animation.
        duration: duration, // How long the animation is to take in seconds.
        callbackFinished: () => {
          // Destory Timer Window
          clearInterval(refTimerPer.current);
          refTimerPer.current = null;
          refWheel.current.animation.spins =
            refWheel.current.animation.spins + spin;
          setDataPeserta((prev) =>
            prev.map((item, index) => {
              if (index === indexPlayer) {
                item.hadiah_actual =
                  refWheel.current.getIndicatedSegment().text;
                item.has_been_drawed = true;
              }
              return item;
            })
          );
          const tempPrize = {
            index: indexPlayer,
            prize: refWheel.current.getIndicatedSegment().text,
          };
          indexPlayer++;
          setState(STOP_STATE);
          setPrize(tempPrize);
          setNumberToSpin((prev) => prev - 1);
        },
      },
      segments: dataSegment,
    });
  });

  async function getSetting() {
    const results = await HttpSetting.GetSetting({
      id_undian: SettingPageId.spin,
    });
    if (results.data && results.data.setting) {
      const temp = JSON.parse(results.data.setting);
      setDataSegment(temp.dataSegment);
      setSetting(temp);
      millisToMinutesAndSeconds(temp.waktuDelay);
      setVideoSrc(getEmmbedLink(temp.linkStreaming));
    }
  }

  async function getPeserta() {
    const results = await HttpPeserta.GetPeserta({
      id_undian: SettingPageId.spin,
      all: true,
      latest: true,
    });
    setNumberToSpin(results.data.length);
    setDataPeserta(results.data);
  }

  function getRandomArbitrary(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }

  function millisToMinutesAndSeconds(millis: number) {
    var minutes: number = Math.floor(millis / 60000);
    var seconds: any = ((millis % 60000) / 1000).toFixed(0);
    setPerTimerMinutes(minutes);
    setPerTimer(seconds);
  }

  function startSpin(indexToSpin: number) {
    if (
      dataPeserta.filter((item) => item.has_been_drawed).length ===
      dataPeserta.length
    ) {
      notification.open({
        message: "Undian Spin Telah Selesai",
        placement: "bottomRight",
        type: "success",
      });
      return;
    }
    // Handle Timer
    if (setting && setting.waktuDelay) {
      if (refTimerPer.current == null) {
        let tempDuration = setting.waktuDelay;
        let isSpinning = false;

        refTimerPer.current = setInterval(() => {
          // Start Spin Function
          setState(START_STATE);
          setWinnerName(dataPeserta[indexToSpin].nama);
          if (isSpinning == false && refWheel.current) {
            const possibleWinningDeg = refWheel.current.segments.filter(
              (item, index) =>
                index > 0 &&
                item.text?.toLowerCase() ===
                  dataPeserta[indexToSpin].hadiah?.toLowerCase()
            );
            const randomPrizeIndex = Math.floor(
              Math.random() * possibleWinningDeg.length
            );
            const segment = possibleWinningDeg[randomPrizeIndex];
            const stopAt = getRandomArbitrary(
              parseInt(segment.startAngle) + 2,
              parseInt(segment.endAngle) - 2
            );
            refWheel.current.animation.stopAngle = stopAt;
            refWheel.current.startAnimation();
            isSpinning = true;
          }
          millisToMinutesAndSeconds(tempDuration);
          tempDuration -= 1000;
        }, 1000);
      }
    }
  }

  function getEmmbedLink(url: string) {
    let res = {
      type: "video", // video or live
      url: url, // string url
    };
    const raw = url.split(".");
    const ext = raw[raw.length - 1];
    if (ext === "m3u8") {
      play.sources[0].src = url;
      res.type = "live";
    } else if (
      new RegExp("\\b" + "youtube" + "\\b").test(url) ||
      new RegExp("\\b" + "youtu.be" + "\\b").test(url)
    ) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      res.url =
        match && match[2].length === 11
          ? `https://www.youtube.com/embed/${match[2]}`
          : "";
    }
    setLoadingStreaming(false);
    return res;
  }

  function onNextClick() {
    const pagginationNum =
      dataPeserta.filter((item) => item.has_been_drawed).length / 10;
    if (page + 1 < Math.ceil(pagginationNum)) {
      setPage((prev) => prev + 1);
    }
  }

  function onPrevClick() {
    if (page > 0) {
      setPage((prev) => prev - 1);
    }
  }

  function renderTile() {
    const temp: any[] = [];
    dataPeserta.map((item, index) => {
      if (index >= page * 10 && index < (page + 1) * 10) {
        temp.push(
          <Fragment key={index}>
            <div className="itemWinnerSpin">
              <div className="itemWinnerSpinNumber">
                {temp.length + 1 + page * 10}
              </div>
              <div className="itemWinnerSpinName">
                {item.has_been_drawed
                  ? MaskingText(item.nama, setting?.hiddenUser)
                  : ""}
              </div>
              <div className="itemWinnerSpinStar">
                <img
                  style={{ width: "100%", height: "100%" }}
                  src="./assets/bulanan/star.png"
                ></img>
              </div>
            </div>
            <div className="itemWinnerSpin">
              <div className="itemWinnerSpinName" style={{ flexBasis: "100%" }}>
                {item.hadiah_actual}
              </div>
            </div>
          </Fragment>
        );
      }
    });
    return temp;
  }

  function setButtonColor() {
    const temp = {
      start: "linear-gradient(0deg, #fff200 0%, #f15a22 100%)",
      stop: "linear-gradient(0deg, #fff200 0%, #f15a22 100%)",
      textStart: "#640000",
      textStop: "#640000",
    };
    switch (state) {
      case START_STATE:
        temp.start =
          "linear-gradient(180deg, rgba(71,170,15,1) 0%, rgba(10,230,23,1) 35%, rgba(0,255,49,1) 100%)";
        temp.stop = "linear-gradient(0deg, #fff200 0%, #f15a22 100%)";
        temp.textStart = "white";
        break;
      case STOP_STATE:
        temp.start = "linear-gradient(0deg, #fff200 0%, #f15a22 100%)";
        temp.stop =
          "linear-gradient(180deg, rgba(199,48,73,1) 0%, rgba(199,48,48,1) 35%, rgba(255,0,0,1) 100%)";
        temp.textStop = "white";
        break;
    }
    return temp;
  }

  function handleStart() {
    if (refTimerPer.current) {
      notification.open({
        message: "Undian Sedang Berlangsung",
        duration: 2000,
        placement: "bottomRight",
        type: "error",
      });
      return;
    }
    setIsPlay(true);
    startSpin(indexPlayer);
  }
  if (!setting) {
    return null;
  }

  return (
    <div
      className="containerCover"
      style={{
        backgroundImage: `url(${
          !process.env.NODE_ENV || process.env.NODE_ENV === "development"
            ? Server.dev
            : Server.prod
        }/media/public?path=${setting.background})`,
      }}
    >
      <div className="containerWrapper">
        <CustomHeader
          logo={setting.logo}
          backgroundColor={setting.warnaHeader}
          label={setting?.textBerjalan}
        ></CustomHeader>
        <div style={{ display: "flex", marginTop: 20 }}>
          <div style={{ flexBasis: "50%" }}>
            <div style={{ display: "flex", height: 200 }}>
              <div
                style={{ width: 100, marginRight: 20, position: "relative" }}
              >
                <CircularProgressbar
                  maxValue={maxMinutes}
                  value={perTimerMinutes}
                  strokeWidth={15}
                  styles={buildStyles({
                    textColor: "white",
                    textSize: 12,
                    trailColor: "gray",
                    pathColor: "red",
                  })}
                />
                <div
                  style={{
                    position: "absolute",
                    color: "white",
                    top: 30,
                    left: 24,
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  Minutes
                  <div style={{ fontSize: 25, marginTop: -7 }}>
                    {perTimerMinutes}
                  </div>
                </div>
              </div>
              <div
                style={{ width: 100, marginRight: 20, position: "relative" }}
              >
                <CircularProgressbar
                  maxValue={60}
                  value={perTimer}
                  strokeWidth={15}
                  styles={buildStyles({
                    textColor: "white",
                    textSize: 12,
                    trailColor: "gray",
                    pathColor: "red",
                  })}
                />
                <div
                  style={{
                    position: "absolute",
                    color: "white",
                    top: 30,
                    left: 24,
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  Seconds
                  <div style={{ fontSize: 25, marginTop: -7 }}>{perTimer}</div>
                </div>
              </div>
              <div
                style={{
                  width: 350,
                  height: 200,
                  border: "1px solid black",
                }}
              >
                {videoSrc && videoSrc.type === "video" ? (
                  <iframe
                    src={videoSrc.src}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <div>
                    {loadingStreaming ? <div></div> : <Video {...play} />}
                  </div>
                )}
              </div>
            </div>
            <Fragment>
              <div
                className="canvasContainer"
                style={{ width: 500, marginTop: 60 }}
              >
                <canvas ref={refCanvas} id="myCanvas" width="500" height="500">
                  Canvas not supported, use another browser.
                </canvas>
                <div className="ring">
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src="./assets/spin/ring.png"
                  ></img>
                </div>
                <div className="pin">
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src="./assets/spin/spin-pointer.png"
                  ></img>
                </div>
                <div className="ball">
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src="./assets/spin/ball.png"
                  ></img>
                </div>
              </div>
              <div className="buttonsContainerSpin">
                <div className="buttons" style={{ display: "flex" }}>
                  <div
                    onClick={handleStart}
                    className="button ubo-button"
                    style={{
                      marginRight: 5,
                      background: setButtonColor().start,
                      color: setButtonColor().textStart,
                    }}
                  >
                    START
                  </div>
                  <div
                    onClick={() => console.log("stop")}
                    className="button ubo-button"
                    style={{
                      background: setButtonColor().stop,
                      color: setButtonColor().textStop,
                    }}
                  >
                    STOP
                  </div>
                </div>
              </div>
            </Fragment>
          </div>
          <div className="containerWinner">
            <div
              style={{
                height: 200,
                width: "100%",
                alignItems: "flex-end",
                justifyContent: "center",
                display: "flex",
                paddingBottom: 20,
              }}
            >
              <div
                style={{
                  border: "2px solid #f15a22",
                  width: "100%",
                  padding: 7,
                  borderRadius: 5,
                }}
              >
                <div
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: 17,
                    height: 27,
                    fontWeight: "bold",
                  }}
                >
                  {MaskingText(winnerName, setting.hiddenUser)}
                </div>
              </div>
            </div>
            <div className="headerWinnerSpin">Username</div>
            <div className="headerWinnerSpin">Hadiah</div>
            {renderTile()}
          </div>
        </div>
      </div>
      <Row>
        <Col xs={24}>
          <CustomFooter
            showButton={true}
            onNext={() => onNextClick()}
            onPrev={() => onPrevClick()}
            footerText={setting.textFooter}
            collections={setting.sponsor}
            marginTop={30}
            backgroundColor={setting.warnaFooter}
          />
        </Col>
      </Row>
    </div>
  );
}
