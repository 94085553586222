import React, { useEffect, useState } from "react";
import { Button, Input, notification, Table } from "antd";
import { ServicePeserta } from "../../../service/ServicePeserta";
import { IPeserta } from "../../../model/Peserta";
import { SettingPageId } from "../../../utils/Constant";
import Modal from "antd/lib/modal/Modal";
import { ServiceHistoryImport } from "../../../service/ServiceHistoryImport";
import { FromStringToDate, FromStringToDateTime } from "../../../utils/Helper";
import CsvDownload from "react-json-to-csv";

interface IPesertaComponent {
  id_undian: number;
  idHistoryImport?: number;
}

export default function PesertaComponent(props: IPesertaComponent) {
  const ServiceHttp = new ServicePeserta();
  const HttpHistoryImport = new ServiceHistoryImport();
  const [data, setData] = useState<IPeserta[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [fileCSV, setFileCSV] = useState<any>();
  const [deskripsi, setDeskripsi] = useState("");
  const [fileName, setFileName] = useState("");
  const [column] = useState([
    {
      title: "No",
      dataIndex: "index_to_show",
      sorter: (a, b) => a.index_to_show - b.index_to_show,
    },
    {
      title: "Nama",
      dataIndex: "nama",
      sorter: (a, b) => {
        const temp = a.nama.localeCompare(b.nama);
        return parseInt(temp);
      },
    },
    {
      title: "Hadiah",
      dataIndex: "hadiah",
      sorter: (a, b) => {
        if (a.hadiah && b.hadiah) {
          return a.hadiah.localeCompare(b.hadiah);
        }
        return 0
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => {
        const temp = a.status.localeCompare(b.status);
        return parseInt(temp);
      },
    },
    {
      title: "Tanggal",
      dataIndex: "tanggal_to_show",
      sorter: (a: any, b: any) => {
        const dateOne = new Date(a.tanggal_undian).getTime();
        const dateTwo = new Date(b.tanggal_undian).getTime();
        const result = (dateOne as any) - (dateTwo as any);
        return result;
      },
    },
  ]);

  useEffect(() => {
    getPeserta();
  }, [props.idHistoryImport, props.id_undian]);

  async function getPeserta() {
    const results = await ServiceHttp.GetPeserta({
      id_undian: props.idHistoryImport === undefined ? props.id_undian : null,
      id_import: props.idHistoryImport || null,
      all: true,
      latest: true,
    });
    setData(
      results.data.map((item, index) => {
        item.index_to_show = index + 1;
        item.tanggal_to_show = FromStringToDateTime(item.tanggal_undian);
        return item;
      })
    );
  }

  async function onUpload() {
    if (fileCSV) {
      const resultsResultsImport = await HttpHistoryImport.add({
        id_undian: props.id_undian,
        deskripsi: deskripsi,
      });
      const results = await ServiceHttp.AddPeserta({
        id_import: resultsResultsImport.data.id,
        id_undian: props.id_undian,
        file: fileCSV,
      });
      if (results.status === 200) {
        window.location.reload();
      } else {
        notification.open({
          message: "Nama File Sudah ada",
          type: "error",
          placement: "bottomRight",
        });
      }
    }
  }

  function getUndianLabel() {
    let label = "";
    for (let i = 0; i < Object.keys(SettingPageId).length; i++) {
      const key = Object.keys(SettingPageId)[i];
      if (SettingPageId[key] === props.id_undian) {
        label = key;
      }
    }
    return `${label[0].toUpperCase()}${label.substring(1)}`;
  }

  function exportToCsv() {
    const dataTemp = data
      .filter((item) => item.status.toLowerCase() !== "kalah")
      .map((item) => {
        return {
          nama: item.nama,
          status: item.status,
          hadiah: item.hadiah_actual,
          tanggal_undian: FromStringToDate(item.tanggal_undian),
        };
      });
    return dataTemp;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBlock: 20,
          width: "100%",
        }}
      >
        <div>
          {!props.idHistoryImport && (
            <Button
              style={{ marginRight: 10 }}
              onClick={() => setShowModal(true)}
            >
              Tambah Peserta
            </Button>
          )}
          <CsvDownload
            data={exportToCsv()}
            filename={`Peserta ${getUndianLabel()}.csv`}
            style={{
              padding: 5,
              border: "1px solid #dedede",
              borderRadius: 5,
              backgroundColor: "white",
            }}
          >
            Export Peserta
          </CsvDownload>
        </div>
      </div>
      <Table
        columns={column}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{ total: data.length }}
      />
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => onUpload()}
      >
        <div>
          <label
            htmlFor="cs"
            style={{
              padding: 5,
              borderRadius: 5,
              border: "1px solid #dedede",
              cursor: "pointer",
            }}
          >
            Upload CSV
          </label>
          <span style={{ marginLeft: 20 }}>{fileName}</span>
          <input
            style={{ display: "none" }}
            id="cs"
            onChange={(e) => {
              setFileCSV(e.target.files && e.target.files[0]);
              if (e.target.files) {
                setFileName(e.target.files[0].name);
              }
            }}
            type="file"
          ></input>
          <Input
            style={{ marginTop: 10 }}
            placeholder="Nama Undian"
            onChange={(e) => setDeskripsi(e.target.value)}
          ></Input>
        </div>
      </Modal>
    </div>
  );
}
