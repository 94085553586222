import React from "react";
import { SettingPageId } from "../../../utils/Constant";
import HistoryImportComponent from "../../../components/ubo/history_import/HistoryImport";

export default function HistoryBulanan() {
  return (
    <div>
      <HistoryImportComponent
        id_undian={SettingPageId.bulanan}
      ></HistoryImportComponent>
    </div>
  );
}
