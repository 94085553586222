import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import { ServiceLogin } from "../../../service/ServiceLogin";
import { IUser } from "../../../model/Users";
import { FromStringToDateTime } from "../../../utils/Helper";
import Register from "../../register/Register";

export default function SettingUser() {
  const HttpLogin = new ServiceLogin();
  const [data, setData] = useState<IUser[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined);
  const [column] = useState([
    {
      title: "Username",
      dataIndex: "username",
    },
    {
      title: "Nama Lengkap",
      dataIndex: "nama",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "status",
      dataIndex: "statusText",
    },
    {
      title: "Login Terakhir",
      dataIndex: "loginText",
    },
  ]);

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    const results = await HttpLogin.GetUser();
    setData(
      results.data.map((item) => {
        item.statusText = item.status === 1 ? "Aktif" : "Non Aktif";
        item.loginText = FromStringToDateTime(item.login);
        return item;
      })
    );
  }

  function onSubmit() {}

  return (
    <div>
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              setSelectedUser(data.find(item => item.username === record.username));
              setShowModal(true);
            },
          };
        }}
        columns={column}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{ pageSize: 10, total: data.length }}
      />
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => onSubmit()}>
        <div>
          <Register
            email={selectedUser?.email}
            id={selectedUser?.id}
            nama={selectedUser?.nama}
            username={selectedUser?.username}
            role={selectedUser?.id_role}
            status={selectedUser?.status}
          />
        </div>
      </Modal>
    </div>
  );
}
