import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React from "react";
import { useNavigate } from "react-router-dom";

interface ILayoutSettingMenu {
  id: number
  text: string
  to: string
}

export interface ILayoutSetting {
  title: string
  menus: ILayoutSettingMenu[]
}

export default function LayoutSetting(props:ILayoutSetting) {
  const navigate = useNavigate()

  function onClick(url:string, index: number) {
    if (index !== 4) {
      navigate(url)
      return
    }
    window.open(url)
  }

  return (
    <div style={{height: 'auto'}}>
      <Menu
        defaultOpenKeys={['sub1']}
        style={{ width: 256, height: "auto" }}
        mode='inline'>
        <SubMenu key='sub1' title={props.title}>
          {
            props.menus.map((item,index) => {
              return (
                <Menu.Item onClick={() => onClick(item.to, index)} key={item.id}>
                  {item.text}
                </Menu.Item>
              )
            })
          }
        </SubMenu>
      </Menu>
    </div>
  );
}
