import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import CustomFooter from "../../components/footer/Footer";
import Generator from "../../components/generator/Generator";
import CustomHeader from "../../components/header/Header";
import { IPeserta } from "../../model/Peserta";
import { ISettingBulanan } from "../../model/SettingBulanan";
import { ServiceHistoryPemenang } from "../../service/ServiceHistoryPemenang";
import { ServicePeserta } from "../../service/ServicePeserta";
import { ServiceSetting } from "../../service/ServiceSetting";
import { Server, SettingPageId } from "../../utils/Constant";
import { MaskingText, ToMoneyFormat } from "../../utils/Helper";
import "./Spesial.css";

interface IHadiahGroupPlay {
  key: string;
  index: number;
}
interface IDataPrizeSpecial {
  img: string;
  text: string;
  orderSlot: number;
  winnerName: string;
  top: number;
  left: number;
  hasBeenDrawed: boolean;
}

export default function Spesial() {
  const HttpPeserta = new ServicePeserta();
  const HttpSetting = new ServiceSetting();
  const HttpHistoryPemenang = new ServiceHistoryPemenang();
  const [dataPeserta, setDataPeserta] = useState<IPeserta[]>([]);
  const [setting, setSetting] = useState<ISettingBulanan | null>(null);
  const [groupPriceIndex, setGroupPriceIndex] = useState<IHadiahGroupPlay[]>(
    []
  );
  const [dataPrize, setDataPrize] = useState<IDataPrizeSpecial[]>([
    {
      img: "",
      text: "",
      orderSlot: 51,
      winnerName: "",
      top: 75,
      left: 12,
      hasBeenDrawed: false,
    },
    {
      img: "",
      text: "",
      orderSlot: 52,
      winnerName: "",
      top: 75,
      left: 31,
      hasBeenDrawed: false,
    },
    {
      img: "",
      text: "",
      orderSlot: 53,
      winnerName: "",
      top: 75,
      left: 50,
      hasBeenDrawed: false,
    },
    {
      img: "",
      text: "",
      orderSlot: 54,
      winnerName: "",
      top: 75,
      left: 68,
      hasBeenDrawed: false,
    },
    {
      img: "",
      text: "",
      orderSlot: 55,
      winnerName: "",
      top: 75,
      left: 86,
      hasBeenDrawed: false,
    },
  ]);

  useEffect(() => {
    getSetting();
    getPeserta();
  }, []);

  async function getSetting() {
    const results = await HttpSetting.GetSetting({
      id_undian: SettingPageId.special,
    });
    if (results.data && results.data.setting) {
      const temp = JSON.parse(results.data.setting);
      const tempHadiah: IHadiahGroupPlay[] = [];
      temp.logoHadiah.forEach((itemTarget, indexTarget) => {
        setDataPrize((prev) =>
          prev.map((item, index) => {
            if (indexTarget === index) {
              item.img = `${Server.prod}/media/public?path=${itemTarget.img}`;
              item.text = itemTarget.key;
            }
            return item;
          })
        );
      });
      temp.hadiahGroup.forEach((item, index) => {
        tempHadiah.push({
          index,
          key: item,
        });
      });
      setGroupPriceIndex(tempHadiah);
      setSetting(temp);
    }
  }

  async function getPeserta() {
    const results = await HttpPeserta.GetPeserta({
      id_undian: SettingPageId.special,
      all: true,
      latest: true,
    });
    const tempMainPeizeWinner = dataPrize;
    tempMainPeizeWinner.map((item) => {
      item.winnerName =
        dataPeserta.find((itemTarget) => {
          return itemTarget.hadiah.toLowerCase() === item.text.toLowerCase();
        })?.nama || "";
      return item;
    });
    setDataPrize(tempMainPeizeWinner);
    setDataPeserta(results.data);
  }

  function winnerSelected(e: IPeserta) {
    HttpHistoryPemenang.add({
      id_undian: SettingPageId.bulanan,
      id_peserta: e.id,
      hadiah: e.hadiah,
      hadiah_actual: e.hadiah,
    });
    setDataPeserta((prev) =>
      prev.map((item) => {
        if (item.id === e.id) {
          item.has_been_drawed = true;
        }
        return item;
      })
    );
  }

  function renderData(group: number) {
    const temp: any = [];
    const tempA = dataPeserta.filter(
      (item) => item.hadiah === groupPriceIndex[group].key
    );
    for (let i = 0; i < 10; i++) {
      temp.push(
        <div key={i} style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              width: "100%",
              padding: 5,
            }}
          >
            <div className="winnerSlot" style={{ height: 30 }}>
              <div className="winnerSlotName">
                {tempA.length > i &&
                  tempA[i].has_been_drawed &&
                  MaskingText(tempA[i].nama, setting?.hiddenUser)}
              </div>
              <div className="winnerSlotNumber">{i + 1 + group * 10}</div>
              <div className="winnerSlotStart">
                <img
                  style={{ width: "100%", height: "100%" }}
                  src="./assets/bulanan/star.png"
                ></img>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return temp;
  }

  if (!setting || dataPeserta.length === 0) {
    return null;
  }

  return (
    <div
      className="containerCover"
      style={{
        backgroundImage: `url(${
          !process.env.NODE_ENV || process.env.NODE_ENV === "development"
            ? Server.dev
            : Server.prod
        }/media/public?path=${setting.background})`,
      }}
    >
      <div className="containerWrapper">
        <CustomHeader
          logo={setting.logo}
          backgroundColor={setting.warnaHeader}
          label={setting.textBerjalan}
        ></CustomHeader>
        <Row style={{ paddingTop: 20 }}>
          <Col xs={24}>
            <div className="barPrize">
              <img
                style={{ width: "100%" }}
                src="./assets/spesial/rak.png"
              ></img>
              {dataPrize.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      className="prizePosImg"
                      style={{ top: `${item.top}%`, left: `${item.left}%` }}
                    >
                      <img style={{ width: "100%" }} src={item.img}></img>
                    </div>
                    <div
                      className="prizePosText"
                      style={{
                        top: `${item.top + 25}%`,
                        left: `${item.left}%`,
                      }}
                    >
                      <div style={{ padding: 5 }}>
                        <div className="prizeTextBox">{item.text}</div>
                      </div>
                    </div>
                    <div
                      className="prizePosWinner"
                      style={{
                        top: `${item.top + 55}%`,
                        left: `${item.left}%`,
                      }}
                    >
                      <div className="SpesialWinnerSlot" style={{ width: 180 }}>
                        <div className="spesialWinnerSlotName">
                          {MaskingText(
                            dataPeserta.find(
                              (itemTarget) =>
                                itemTarget.hadiah === item.text &&
                                itemTarget.has_been_drawed
                            )?.nama || "",
                            setting.hiddenUser
                          )}
                        </div>
                        <div className="spesialWinnerSlotNumber">
                          {item.orderSlot}
                        </div>
                        <div className="spesialWinnerSlotStart">
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src="./assets/bulanan/star.png"
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: 120,
              }}
            >
              <Generator
                showHidden={setting.hiddenUser}
                title={setting.textHeader}
                auto={setting.isAuto}
                onWinnerSelected={(e) => winnerSelected(e)}
                data={dataPeserta}
                timing={setting.waktuDelay}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", marginTop: 30 }}>
          {groupPriceIndex.map((item, index) => {
            return (
              <div key={index} style={{ flexBasis: "20%" }}>
                <div style={{ padding: 5 }}>
                  <div className="headerWinner">
                    IDR {ToMoneyFormat(item.key)}
                  </div>
                </div>
                {renderData(index)}
              </div>
            );
          })}
        </div>
        <Row>
          <Col xs={24}>
            <CustomFooter
              showButton={false}
              footerText={setting.textFooter}
              collections={setting.sponsor}
              marginTop={20}
              backgroundColor={setting.warnaFooter}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
