import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ILogin } from "../model/Users";
import { TokenAtomState } from "../state/AtomToken";
import { KeyToken, UserRole } from "../utils/Constant";
import "./Layout.css";

interface ILayout {
  hideOutlet?: boolean;
}

export default function Layout(props: ILayout) {
  const navigate = useNavigate();
  const hamburger = useRef<any>();
  const navMenu = useRef<any>();
  const [, setTokenAtom] = useRecoilState(TokenAtomState);
  const [isLoading, setIsLoading] = useState(true);
  const [menu, setMenu] = useState([
    { label: "Home", dir: "/" },
    { label: "Bulanan", dir: "/setting-bulanan" },
    { label: "Hari Raya", dir: "/setting-hari-raya" },
    { label: "Special", dir: "/setting-special" },
    { label: "Spin", dir: "/setting-spin" },
  ]);

  useEffect(() => {
    const tempJson = localStorage.getItem(KeyToken);
    if (tempJson) {
      const token = JSON.parse(tempJson);
      if (token.idRole === UserRole.admin) {
        setMenu((prev) => [
          ...prev,
          {
            label: "User",
            dir: "/setting-user",
          },
        ]);
      }
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      hamburger.current = document.querySelector(".hamburger");
      navMenu.current = document.querySelector(".nav-menu");
      hamburger.current.addEventListener("click", mobileMenu);
    }
  }, [isLoading]);

  function mobileMenu() {
    hamburger.current.classList.toggle("active");
    navMenu.current.classList.toggle("active");
  }

  function navigateTo(dir: string) {
    navigate(dir);
    hamburger.current.classList.remove("active");
    navMenu.current.classList.remove("active");
  }

  function getUserType() {
    const temp = localStorage.getItem(KeyToken);
    if (temp) {
      const token = JSON.parse(temp);
      return token.idRole === 1 ? "Admin" : "Operator";
    }
    return "Dashboard";
  }

  function logout() {
    localStorage.clear();
    setTokenAtom({} as ILogin);
    navigate("/login");
  }

  function isActive(url: string) {
    const temp = window.location.pathname.substring(1).split("/")[0];
    return url.substring(1) === temp;
  }

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <header className="header">
        <nav className="navbar">
          <div className="nav-logo">{getUserType()}</div>
          <ul className="nav-menu">
            {menu.map((item, index) => {
              return (
                <li key={index} className="nav-item">
                  <div
                    onClick={() => navigateTo(item.dir)}
                    className="nav-link"
                  >
                    {item.label}
                    {isActive(item.dir) && (
                      <div
                        style={{
                          backgroundColor: "#2d55ff",
                          width: "100%",
                          height: 2,
                        }}
                      ></div>
                    )}
                  </div>
                </li>
              );
            })}
            <li className="nav-item">
              <div onClick={() => logout()} className="nav-link">
                logout
              </div>
            </li>
          </ul>
          <div className="hamburger">
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </nav>
      </header>
      <div>{!props.hideOutlet && <Outlet></Outlet>}</div>
    </div>
  );
}
