import { ICreatePeserta, IGetPeserta } from "../model/Peserta";
import { AddToLog } from "../utils/Helper";
import { Request } from "../utils/Http";

export class ServicePeserta {
  public async AddPeserta({
    id_import,
    id_undian,
    file,
  }: {
    id_import: number;
    id_undian: number;
    file: any;
  }): Promise<ICreatePeserta> {
    const results = await Request({
      url: "/peserta",
      method: "POST",
      useFormMultiPart: true,
      data: {
        id_import,
        id_undian,
        file,
      },
    });
    return results;
  }

  public async UpdatePeserta({
    id,
    hadiah,
    status,
    nama,
    pemenang_ke
  }: {
    id: number;
    hadiah?: string;
    status?: string;
    nama?: string;
    pemenang_ke?:number
  }): Promise<ICreatePeserta> {
    AddToLog(
      `Melakukan perubahan pada peserta nama:${nama} dengan status:${status}`
    );
    const results = await Request({
      url: "/peserta",
      method: "PUT",
      data: {
        id,
        hadiah,
        status,
        nama,
        pemenang_ke
      },
    });
    return results;
  }

  public async GetPeserta({
    id_undian,
    id_import,
    latest,
    all,
    limit,
  }: {
    id_undian?: number | null;
    id_import?:number | null;
    latest?: boolean;
    all?: boolean;
    limit?: number;
  }): Promise<IGetPeserta> {
    const results = await Request({
      url: "/peserta",
      method: "GET",
      params: {
        id_undian,
        id_import,
        latest,
        all,
        limit,
      },
    });
    return results;
  }
}
