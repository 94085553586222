import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { AddToLog, FromStringToDateTime } from "../../../utils/Helper";
import { ServiceLog } from "../../../service/ServiceLog";
import { ILog } from "../../../model/Log";

export default function UserLog() {
  const HttpLog = new ServiceLog();
  const [data, setData] = useState<ILog[]>([]);
  const [column] = useState([
    {
      title: "Tanggal",
      dataIndex: "tanggalText",
    },
    {
      title: "Username",
      dataIndex: "username",
    },
    {
      title: "Keterangan",
      dataIndex: "deskripsi",
    },
  ]);

  useEffect(() => {
    getLog();
  }, []);

  async function getLog() {
    const results = await HttpLog.GetLog();
    setData(
      results.data.map((item) => {
        item.tanggalText = FromStringToDateTime(item.tanggal.toString());
        return item;
      })
    );
  }

  return (
    <div>
      <Table
        columns={column}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{ pageSize: 10, total: data.length }}
      />
    </div>
  );
}
