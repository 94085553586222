import { Button, Col, Form, Input, Row, Switch } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import UploadImage from "../../../components/upload_image/UploadImage";
import { ILayoutSetting } from "../../../layout/setting/LayoutSetting";
import { ISettingBulanan } from "../../../model/SettingBulanan";
import { ServiceSetting } from "../../../service/ServiceSetting";
import { SettingPageId } from "../../../utils/Constant";

export default function SettingBulanan() {
  const SettingHttp = new ServiceSetting();
  const [waktuDelay, setWaktuDelay] = useState(1500);
  const [isAuto, setIsAuto] = useState(true);
  const [textBerjalan, setTextBerjalan] = useState("");
  const [textHeader, setTextHeader] = useState("");
  const [textFooter, setTextFooter] = useState("");
  const [logo, setLogo] = useState("");
  const [background, setBackground] = useState("");
  const [sponsor, setSponsor] = useState<string[]>([]);
  const [warnaHeader, setwarnaHeader] = useState("");
  const [warnaFooter, setwarnaFooter] = useState("");
  const [warnaKolom, setwarnaKolom] = useState("");
  const [linkStreaming, setLinkStreaming] = useState("");
  const [logoHeader, setLogoHeader] = useState("");
  const [hiddenUser, setHiddenUser] = useState(true);
  const menu: ILayoutSetting = {
    title: "Setting Bulanan",
    menus: [
      { id: 1, text: "Desain", to: "/setting-bulanan" },
      { id: 2, text: "Peserta", to: "/setting-bulanan/peserta" },
      { id: 3, text: "Histori", to: "/setting-bulanan/histori" },
      { id: 4, text: "Custom Pemenang", to: "/setting-bulanan/pemenang" },
    ],
  };

  useEffect(() => {
    getSetting();
  }, []);

  async function getSetting() {
    const results = await SettingHttp.GetSetting({
      id_undian: SettingPageId.bulanan,
    });
    if (results.data.setting) {
      const tempJson: ISettingBulanan = JSON.parse(results.data.setting);
      setWaktuDelay(tempJson.waktuDelay);
      setIsAuto(tempJson.isAuto);
      setTextBerjalan(tempJson.textBerjalan);
      setTextHeader(tempJson.textHeader);
      setTextFooter(tempJson.textFooter);
      setLogo(tempJson.logo);
      setBackground(tempJson.background);
      setSponsor(tempJson.sponsor);
      setwarnaFooter(tempJson.warnaFooter);
      setwarnaHeader(tempJson.warnaHeader);
      setTextFooter(tempJson.textFooter);
      setwarnaKolom(tempJson.warnaKolom);
      setLinkStreaming(tempJson.linkStreaming);
      setHiddenUser(tempJson.hiddenUser);
      setLogoHeader(tempJson.logoHeader);
    }
  }

  async function addSetting() {
    let jsonSetting = {
      waktuDelay,
      isAuto,
      textBerjalan,
      textHeader,
      textFooter,
      logo,
      background,
      sponsor,
      warnaHeader,
      warnaFooter,
      warnaKolom,
      linkStreaming,
      hiddenUser,
      logoHeader,
    };
    const resutls = await SettingHttp.AddSetting({
      id_undian: 1,
      setting: JSON.stringify(jsonSetting),
    });
  }

  function handleDelete(target: number) {
    setSponsor((prev) => prev.filter((item, index) => index !== target));
  }

  function handleUpdate(url: string, target: number) {
    setSponsor((prev) =>
      prev.map((item, index) => {
        if (index === target) {
          item = url;
        }
        return item;
      })
    );
  }

  function handleUploadSponsor(url: string[]) {
    setSponsor((prev) => [...prev, ...url]);
  }

  return (
    <Fragment>
      <Form layout="vertical">
        <Form.Item label="Waktu Delay (milidetik)">
          <Input
            value={waktuDelay}
            onChange={(e: any) =>
              setWaktuDelay(e.target.value ? parseInt(e.target.value) : 1500)
            }
            style={{ width: "100%" }}
            placeholder="Waktu Delay (milidetik)"
            type="number"
          />
        </Form.Item>
        <Form.Item label="Hidden User">
          <Switch
            onChange={(e) => setHiddenUser(e)}
            checkedChildren="Visible"
            unCheckedChildren="Hidden"
            checked={hiddenUser}
          />
        </Form.Item>
        <Form.Item label="Jenis Putaran Undian">
          <Switch
            onChange={(e) => setIsAuto(e)}
            checkedChildren="Auto"
            unCheckedChildren="Manual"
            checked={isAuto}
          />
        </Form.Item>
        <Form.Item label="Text Berjalan">
          <Input
            value={textBerjalan}
            onChange={(e) => setTextBerjalan(e.target.value)}
            style={{ width: "100%" }}
            placeholder="input placeholder"
          />
        </Form.Item>
        <Form.Item label="Text Header">
          <Input
            value={textHeader}
            onChange={(e) => setTextHeader(e.target.value)}
            style={{ width: "100%" }}
            placeholder="input placeholder"
          />
        </Form.Item>
        <Form.Item label="Text Footer">
          <Input
            value={textFooter}
            onChange={(e) => setTextFooter(e.target.value)}
            style={{ width: "100%" }}
            placeholder="input placeholder"
          />
        </Form.Item>
        <Form.Item label="Logo">
          <UploadImage
            collections={[logo]}
            onUpload={(e) => setLogo(e[0] || "")}
          />
        </Form.Item>
        <Form.Item label="Header">
          <UploadImage
            collections={[logoHeader]}
            onUpload={(e) => setLogoHeader(e[0] || "")}
          />
        </Form.Item>
        <Form.Item label="Background">
          <UploadImage
            collections={[background]}
            onUpload={(e) => setBackground(e[0] || "")}
          />
        </Form.Item>
        <Form.Item label="Sponsor">
          <UploadImage
            multiple={true}
            collections={sponsor}
            onUpload={(e) => handleUploadSponsor(e)}
            onUpdate={(e, index) => handleUpdate(e, index)}
            onDelete={(e) => handleDelete(e)}
          />
        </Form.Item>
        <Row justify="space-between">
          <Col xs={7}>
            <Form.Item label="Warna Header">
              <Input
                value={warnaHeader}
                onChange={(e) => setwarnaHeader(e.target.value)}
                style={{ width: "100%", height: 75, cursor: "pointer" }}
                placeholder="Warna Header"
                type="color"
              />
            </Form.Item>
          </Col>
          <Col xs={7}>
            <Form.Item label="Warna Footer">
              <Input
                value={warnaFooter}
                onChange={(e) => setwarnaFooter(e.target.value)}
                style={{ width: "100%", height: 75, cursor: "pointer" }}
                placeholder="Warna Footer"
                type="color"
              />
            </Form.Item>
          </Col>
          <Col xs={7}>
            <Form.Item label="Warna Kolom">
              <Input
                value={warnaKolom}
                onChange={(e) => setwarnaKolom(e.target.value)}
                style={{ width: "100%", height: 75, cursor: "pointer" }}
                placeholder="Warna Kolom"
                type="color"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Link Streaming">
          <Input
            value={linkStreaming}
            onChange={(e) => setLinkStreaming(e.target.value)}
            style={{ width: "100%" }}
            placeholder="Link Streaming"
          />
        </Form.Item>
        <Button onClick={() => addSetting()}>Submit</Button>
      </Form>
    </Fragment>
  );
}
