import { Request } from "../../../utils/Http";
import { ICreateUploadImage } from "../model/IUploadImage";

export class ServiceUploadImage {
  public async AddImage(file:any):Promise<ICreateUploadImage> {
    const results = await Request({
      url: '/media',
      method: 'POST',
      useFormMultiPart: true,
      data: {
        file
      }
    })
    return results
  }
}