import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import BulananWinner from "../../components/bulanan_winner/BulananWinner";
import CustomFooter from "../../components/footer/Footer";
import CustomHeader from "../../components/header/Header";
import "./Bulanan.css";
import Generator from "../../components/generator/Generator";
import { ServicePeserta } from "../../service/ServicePeserta";
import { Server, SettingPageId } from "../../utils/Constant";
import { IPeserta } from "../../model/Peserta";
import { ServiceHistoryPemenang } from "../../service/ServiceHistoryPemenang";
import { ServiceSetting } from "../../service/ServiceSetting";
import { ISettingBulanan } from "../../model/SettingBulanan";
import videojs from "video.js";
import "video.js/dist/video-js.css";

// video
const play = {
  fill: true,
  fluid: true,
  autoplay: true,
  controls: true,
  preload: "metadata",
  muted: true,
  sources: [
    {
      src: "",
      type: "application/x-mpegURL",
    },
  ],
};

const Video = (props) => {
  const videoNode = useRef<any>(null);
  const [player, setPlayer] = useState<any>(null);
  useEffect(() => {
    if (videoNode.current) {
      const _player = videojs(videoNode.current, props);
      setPlayer(_player);
      return () => {
        if (player !== null) {
          player.dispose();
        }
      };
    }
  }, []);

  return (
    <div data-vjs-player>
      <video ref={videoNode} className="video-js"></video>
    </div>
  );
};

export default function Bulanan() {
  const HttpHistoryPemenang = new ServiceHistoryPemenang();
  const HttpSetting = new ServiceSetting();
  const HttpPeserta = new ServicePeserta();
  const [dataPeserta, setDataPeserta] = useState<IPeserta[]>([]);
  const [dataPesertaWinner, setDataPesertaWinner] = useState<IPeserta[]>([]);
  const [setting, setSetting] = useState<ISettingBulanan | null>(null);
  const [page, setPage] = useState(0);
  const [videoSrc, setVideoSrc] = useState<any>();
  const [ctr, setCtr] = useState(0);

  useEffect(() => {
    getPeserta();
    getSetting();
  }, []);

  useEffect(() => {
    if (dataPeserta.length > 0) {
      const temp: IPeserta[] = Array(25).fill({
        nama: "",
        id: -1,
      } as IPeserta);
      let ctr = 0;
      dataPeserta
        .filter((itemFilter) => itemFilter.has_been_drawed)
        .forEach((item, index) => {
          if (index > page * 25 - 1 && index < (page + 1) * 25) {
            temp[ctr] = item;
            ctr++;
          }
        });
      setDataPesertaWinner(temp);
    }
  }, [page, dataPeserta]);

  useEffect(() => {
    if (ctr > 25) {
      setCtr(0);
      onNextClick();
    }
  }, [ctr]);

  async function getSetting() {
    const results = await HttpSetting.GetSetting({
      id_undian: SettingPageId.bulanan,
    });
    if (results.data && results.data.setting) {
      const temp = JSON.parse(results.data.setting);
      setSetting(temp);
      setVideoSrc(getEmmbedLink(temp.linkStreaming));
    }
  }

  async function getPeserta() {
    const results = await HttpPeserta.GetPeserta({
      id_undian: SettingPageId.bulanan,
      all: true,
      latest: true,
    });
    setDataPeserta(results.data);
  }

  function winnerSelected(e: IPeserta) {
    HttpHistoryPemenang.add({
      id_undian: SettingPageId.bulanan,
      id_peserta: e.id,
      hadiah: "100k",
      hadiah_actual: "100k",
    });
    setDataPesertaWinner((prev) => {
      let skip = false;
      return prev.map((item) => {
        if (item.id === -1 && !skip) {
          item = e;
          skip = true;
        }
        return item;
      });
    });
    setCtr((prev) => prev + 1);
  }

  function getEmmbedLink(url: string) {
    let res = {
      type: "video", // video or live
      url: url, // string url
    };
    const raw = url.split(".");
    const ext = raw[raw.length - 1];
    if (ext === "m3u8") {
      play.sources[0].src = url;
      res.type = "live";
    } else if (
      new RegExp("\\b" + "youtube" + "\\b").test(url) ||
      new RegExp("\\b" + "youtu.be" + "\\b").test(url)
    ) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      res.url =
        match && match[2].length === 11
          ? `https://www.youtube.com/embed/${match[2]}`
          : "";
    }
    return res;
  }

  function onNextClick() {
    const max =
      dataPeserta.filter((item) => item.status.toLowerCase() === "menang")
        .length / 25;
    if (page + 1 < max) {
      const pagginationNum =
        dataPeserta.filter((item) => item.has_been_drawed).length / 25;
      if (page + 1 < Math.ceil(pagginationNum)) {
        setPage((prev) => prev + 1);
      }
    }
  }

  function onPrevClick() {
    if (page > 0) {
      setPage((prev) => prev - 1);
    }
  }

  if (!setting) {
    return null;
  }

  return (
    <div
      className="containerCover"
      style={{
        backgroundImage: `url(${
          !process.env.NODE_ENV || process.env.NODE_ENV === "development"
            ? Server.dev
            : Server.prod
        }/media/public?path=${setting.background})`,
      }}
    >
      <div className="containerWraper">
        <CustomHeader
          logo={setting.logo}
          backgroundColor={setting.warnaHeader}
          label={setting.textBerjalan}
        ></CustomHeader>
        <Row>
          <Col xs={10}>
            <Col xs={24}>
              <div style={{ width: 600, marginBlock: 20, zIndex: 9999 }}>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={`${
                    !process.env.NODE_ENV ||
                    process.env.NODE_ENV === "development"
                      ? Server.dev
                      : Server.prod
                  }/media/public?path=${setting.logoHeader}`}
                ></img>
              </div>
            </Col>
            <Col xs={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Generator
                  showHidden={setting.hiddenUser}
                  title={setting.textHeader}
                  auto={setting.isAuto}
                  onWinnerSelected={(e) => {
                    winnerSelected(e);
                  }}
                  data={dataPeserta}
                  dataPer={25}
                  timing={setting.waktuDelay}
                />
              </div>
            </Col>
            <Col xs={24}>
              <div className="videoContainer">
                <div className="videoFrame">
                  {videoSrc && videoSrc.type === "video" ? (
                    <iframe
                      src={videoSrc.src}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  ) : (
                    <Video {...play} />
                  )}
                </div>
              </div>
            </Col>
          </Col>
          <Col xs={14}>
            <div>
              <Row>
                <Col xs={24} style={{ marginTop: 100 }}></Col>
                <Col xs={8}>
                  {/* placeholder to hide layout */}
                  <BulananWinner
                    order={1}
                    name="pop"
                    show={false}
                  ></BulananWinner>
                  <BulananWinner
                    order={1}
                    name="pop"
                    show={false}
                  ></BulananWinner>
                  <BulananWinner
                    order={1}
                    name="pop"
                    show={false}
                  ></BulananWinner>
                  <BulananWinner
                    order={1}
                    name="pop"
                    show={false}
                  ></BulananWinner>
                  <BulananWinner
                    order={1}
                    name="pop"
                    show={false}
                  ></BulananWinner>
                  {/* placeholder to hide layout */}
                  {dataPesertaWinner &&
                    dataPesertaWinner.map((item, index) => {
                      if (index <= 4) {
                        return (
                          <BulananWinner
                            key={index}
                            order={index + 1 + page * 25}
                            name={item.nama || ""}
                            visible={setting.hiddenUser}
                          ></BulananWinner>
                        );
                      }
                    })}
                </Col>
                <Col xs={8}>
                  {dataPesertaWinner &&
                    dataPesertaWinner.map((item, index) => {
                      if (index > 4 && index <= 14) {
                        return (
                          <BulananWinner
                            key={index}
                            order={index + 1 + page * 25}
                            name={item.nama || ""}
                            visible={setting.hiddenUser}
                          ></BulananWinner>
                        );
                      }
                    })}
                </Col>
                <Col xs={8}>
                  {dataPesertaWinner &&
                    dataPesertaWinner.map((item, index) => {
                      if (index > 14 && index <= 24) {
                        return (
                          <BulananWinner
                            key={index}
                            order={index + 1 + page * 25}
                            name={item.nama || ""}
                            visible={setting.hiddenUser}
                          ></BulananWinner>
                        );
                      }
                    })}
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24}>
            <CustomFooter
              showButton={true}
              onNext={() => onNextClick()}
              onPrev={() => onPrevClick()}
              buttonText={page === 0 ? "NEXT >>" : "PREVIOUS <<"}
              backgroundColor={setting.warnaFooter}
              footerText={setting.textFooter}
              collections={setting.sponsor}
              marginTop={20}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
