import { Card, Col, Row } from "antd";
import React, { Fragment } from "react";
import Layout from "../../layout/Layout";
import "./Home.css";

export default function Home() {
  const MENU = [
    { label: "Bulanan", to: "/bulanan" },
    { label: "Hari Raya", to: "/hari-raya" },
    { label: "Special", to: "/special" },
    { label: "Spin", to: "/spin" },
  ];

  return (
    <Fragment>
      <Layout></Layout>
      <div style={{ padding: 20 }}>
        <Row gutter={12}>
          {MENU.map((item, index) => {
            return (
              <Col
                onClick={() => window.open(item.to)}
                key={index}
                xs={24}
                md={6}
                lg={6}
                style={{ marginBottom: 20, cursor: "pointer" }}
              >
                <Card title={item.label}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <i
                      style={{ fontSize: 20 }}
                      className="fa-solid fa-play"
                    ></i>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </Fragment>
  );
}
