import { Button, Col, Form, Input, Row, Switch } from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ListUploaded from "../../../components/upload_image/ListUploaded";
import { IUploadImage } from "../../../components/upload_image/model/IUploadImage";
import UploadImage from "../../../components/upload_image/UploadImage";
import { ISegmentData, ISettingBulanan } from "../../../model/SettingBulanan";
import { ServiceSetting } from "../../../service/ServiceSetting";
import { SettingPageId } from "../../../utils/Constant";

export default function SettingSpin() {
  const SettingHttp = new ServiceSetting();
  const winWheelJs = require("winwheel");
  const refCanvas = useRef<any>();
  const refWheel = useRef<any>();
  const [waktuDelay, setWaktuDelay] = useState(1500);
  const [isAuto, setIsAuto] = useState(true);
  const [textBerjalan, setTextBerjalan] = useState("");
  const [textHeader, setTextHeader] = useState("");
  const [textFooter, setTextFooter] = useState("");
  const [logo, setLogo] = useState("");
  const [background, setBackground] = useState("");
  const [sponsor, setSponsor] = useState<string[]>([]);
  const [warnaHeader, setwarnaHeader] = useState("");
  const [warnaFooter, setwarnaFooter] = useState("");
  const [warnaKolom, setwarnaKolom] = useState("");
  const [linkStreaming, setLinkStreaming] = useState("");
  const [dataSegment, setDataSegment] = useState<ISegmentData[]>([]);
  const [hiddenUser, setHiddenUser] = useState(true);

  useEffect(() => {
    getSetting();
  }, []);

  useEffect(() => {
    if (!refCanvas.current || refWheel.current) {
      return;
    }
    refWheel.current = new winWheelJs({
      canvasId: "myCanvas",
      numSegments: 20,
      fillStyle: "white",
      lineWidth: 0,
      innerRadius: 50,
      pointerAngle: 270,
      rotationAngle: -7,
      textDirection: "reversed",
      segments: dataSegment,
    });
  });

  useEffect(() => {
    if (dataSegment.length > 0) {
      const temp = dataSegment.map((item) => {
        if (!item.text.includes("-")) {
          item.text = `${item.id}-${item.text}`;
        }
        return item;
      });
      refWheel.current = new winWheelJs({
        canvasId: "myCanvas",
        numSegments: 20,
        fillStyle: "white",
        lineWidth: 0,
        innerRadius: 50,
        pointerAngle: 270,
        rotationAngle: -7,
        textDirection: "reversed",
        segments: temp,
      });
    }
  }, [dataSegment]);

  async function getSetting() {
    const results = await SettingHttp.GetSetting({
      id_undian: SettingPageId.spin,
    });
    if (results.data.setting) {
      const tempJson: ISettingBulanan = JSON.parse(results.data.setting);
      setWaktuDelay(tempJson.waktuDelay);
      setIsAuto(tempJson.isAuto);
      setTextBerjalan(tempJson.textBerjalan);
      setTextFooter(tempJson.textFooter);
      setLogo(tempJson.logo);
      setBackground(tempJson.background);
      setSponsor(tempJson.sponsor);
      setwarnaHeader(tempJson.warnaHeader);
      setTextFooter(tempJson.textFooter);
      setwarnaKolom(tempJson.warnaKolom);
      setwarnaHeader(tempJson.warnaHeader);
      setwarnaFooter(tempJson.warnaFooter);
      setLinkStreaming(tempJson.linkStreaming);
      setHiddenUser(tempJson.hiddenUser)
      setDataSegment(
        tempJson.dataSegment.map((item) => {
          item.text = `${item.id}-${item.text}`;
          return item;
        })
      );
    }
  }

  async function addSetting() {
    dataSegment.map((item) => {
      if (item.text.includes("-")) {
        item.text = item.text.split("-")[1];
      }
      return item;
    });
    let jsonSetting = {
      waktuDelay,
      isAuto,
      textBerjalan,
      textHeader,
      textFooter,
      logo,
      background,
      sponsor,
      warnaHeader,
      warnaFooter,
      warnaKolom,
      linkStreaming,
      dataSegment,
      hiddenUser
    };
    const resutls = await SettingHttp.AddSetting({
      id_undian: SettingPageId.spin,
      setting: JSON.stringify(jsonSetting),
    });
  }

  function changeValueTile(
    tile: ISegmentData,
    value: string,
    type: "text" | "fillStyle"
  ) {
    setDataSegment((prev) => {
      return prev.map((item) => {
        if (item.id === tile.id) {
          item[type] = value;
        }
        return item;
      });
    });
  }

  function getMaskingValue(text: string) {
    if (text.includes("-")) {
      return text.split("-")[1];
    }
    return text;
  }

  function renderFormTile(start: number) {
    const temp: any = [];
    for (let i = start; i < start + 10; i++) {
      temp.push(
        <Row key={i} justify="space-between">
          <Col xs={2}>
            <h3>{i + 1}</h3>
          </Col>
          <Col xs={15}>
            <Input
              value={getMaskingValue(dataSegment[i].text)}
              onChange={(e) =>
                changeValueTile(dataSegment[i], e.target.value, "text")
              }
              style={{ width: "100%" }}
              placeholder="input placeholder"
            />
          </Col>
          <Col xs={5}>
            <Input
              value={dataSegment[i].fillStyle}
              type="color"
              onChange={(e) =>
                changeValueTile(dataSegment[i], e.target.value, "fillStyle")
              }
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      );
    }
    return temp;
  }

  function handleDelete(target:number) {
    setSponsor(prev => prev.filter((item,index) => index !== target))
  }

  function handleUpdate(url: string, target: number) {
    setSponsor((prev) =>
      prev.map((item, index) => {
        if (index === target) {
          item = url;
        }
        return item;
      })
    );
  }

  function handleUploadSponsor(url: string[]) {
    setSponsor((prev) => [...prev, ...url]);
  }

  if (dataSegment.length <= 0) {
    return <div></div>;
  }

  return (
    <Fragment>
      <Form layout="vertical">
        <Form.Item label="Waktu Delay (milidetik)">
          <Input
            value={waktuDelay}
            onChange={(e: any) => {
              setWaktuDelay(parseInt(e.target.value));
            }}
            style={{ width: "100%" }}
            placeholder="Waktu Delay (milidetik)"
            type="number"
          />
        </Form.Item>
        <Form.Item label="Hidden User">
          <Switch
            onChange={(e) => setHiddenUser(e)}
            checkedChildren="Visible"
            unCheckedChildren="Hidden"
            checked={hiddenUser}
          />
        </Form.Item>
        <Form.Item label="Jenis Putaran Undian">
          <Switch
            onChange={(e) => setIsAuto(e)}
            checkedChildren="Auto"
            unCheckedChildren="Manual"
            checked={isAuto}
          />
        </Form.Item>
        <Form.Item label="Text Berjalan">
          <Input
            value={textBerjalan}
            onChange={(e) => setTextBerjalan(e.target.value)}
            style={{ width: "100%" }}
            placeholder="input placeholder"
          />
        </Form.Item>
        <Form.Item label="Text Footer">
          <Input
            value={textFooter}
            onChange={(e) => setTextFooter(e.target.value)}
            style={{ width: "100%" }}
            placeholder="input placeholder"
          />
        </Form.Item>
        <Form.Item label="Logo">
          <UploadImage
            collections={[logo]}
            onUpload={(e) => setLogo(e[0] || "")}
          />
        </Form.Item>
        <Form.Item label="Background">
          <UploadImage
            collections={[background]}
            onUpload={(e) => setBackground(e[0] || "")}
          />
        </Form.Item>
        <Form.Item label="Sponsor">
          <UploadImage
            multiple={true}
            collections={sponsor}
            onUpload={(e) => handleUploadSponsor(e)}
            onUpdate={(e, index) => handleUpdate(e, index)}
            onDelete={(e) => handleDelete(e)}
          />
        </Form.Item>
        <Row justify="space-between">
          <Col xs={7}>
            <Form.Item label="Warna Header">
              <Input
                value={warnaHeader}
                onChange={(e) => setwarnaHeader(e.target.value)}
                style={{ width: "100%", height: 75, cursor: "pointer" }}
                placeholder="Warna Header"
                type="color"
              />
            </Form.Item>
          </Col>
          <Col xs={7}>
            <Form.Item label="Warna Footer">
              <Input
                value={warnaFooter}
                onChange={(e) => setwarnaFooter(e.target.value)}
                style={{ width: "100%", height: 75, cursor: "pointer" }}
                placeholder="Warna Footer"
                type="color"
              />
            </Form.Item>
          </Col>
          <Col xs={7}>
            <Form.Item label="Warna Kolom">
              <Input
                value={warnaKolom}
                onChange={(e) => setwarnaKolom(e.target.value)}
                style={{ width: "100%", height: 75, cursor: "pointer" }}
                placeholder="Warna Kolom"
                type="color"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Link Streaming">
          <Input
            value={linkStreaming}
            onChange={(e) => setLinkStreaming(e.target.value)}
            style={{ width: "100%" }}
            placeholder="Link Streaming"
          />
        </Form.Item>
        <div style={{ marginBlock: 20 }}>
          <Row>
            <Col xs={12}>
              <Row justify="space-between">
                <Col xs={12}>{renderFormTile(0)}</Col>
                <Col xs={11}>{renderFormTile(10)}</Col>
              </Row>
            </Col>
            <Col xs={12}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <canvas ref={refCanvas} id="myCanvas" width="400" height="400">
                  Canvas not supported, use another browser.
                </canvas>
              </div>
            </Col>
          </Row>
        </div>
        <Button onClick={() => addSetting()}>Submit</Button>
      </Form>
    </Fragment>
  );
}
