import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Table, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import { IPeserta } from "../../model/Peserta";
import { ServicePeserta } from "../../service/ServicePeserta";

interface ICustomBulanan {
  settingPageId: number
}

export default function CustomWinner(props:ICustomBulanan) {
  const ServiceHttp = new ServicePeserta();
  const [data, setData] = useState<IPeserta[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedPeserta, setSelectedPeserta] = useState<IPeserta>();
  const [selectedPesertaDrop, setSelectedPesertaDrop] = useState<IPeserta>();
  const [name, setName] = useState<string>();
  const [hadiah, setHadiah] = useState<string>();
  const [status, setStatus] = useState<string>();
  const [column] = useState([
    {
      title: "Pemenang Ke",
      dataIndex: "pemenang_ke",
    },
    {
      title: "Nama",
      dataIndex: "nama",
    },
    {
      title: "Hadiah",
      dataIndex: "hadiah",
    },
  ]);

  useEffect(() => {
    getPeserta();
  }, []);

  useEffect(() => {
    if (selectedPeserta) {
      setName(selectedPeserta.nama);
      setHadiah(selectedPeserta.hadiah);
      setStatus(selectedPeserta.status);
      setSelectedPesertaDrop(selectedPeserta);
      setShowModal(true);
    }
  }, [selectedPeserta]);

  async function getPeserta() {
    const results = await ServiceHttp.GetPeserta({
      id_undian: props.settingPageId,
      all: true,
      latest: true,
    });
    setData(results.data);
  }

  async function updatePeserta() {
    if (name && selectedPeserta && selectedPesertaDrop) {
      const results = await ServiceHttp.UpdatePeserta({
        id: selectedPeserta.id,
        nama: selectedPeserta.nama,
        pemenang_ke: 0,
        status: 'KALAH'
      });
      const results2 = await ServiceHttp.UpdatePeserta({
        id: selectedPesertaDrop.id,
        nama: selectedPesertaDrop.nama,
        status: "MENANG",
        pemenang_ke: selectedPeserta.pemenang_ke,
        hadiah: selectedPeserta.hadiah
      });
      if (results.status === 200 && results2.status === 200) {
        window.location.reload();
      }
    }
  }

  function getSelectOptions() {
    let temp: any[] = [];
    data
      .filter((item) => item.status.toLowerCase() !== "menang")
      .forEach((item, index) => {
        temp.push(
          <Select.Option key={index + 1} value={item.id}>
            {item.nama}
          </Select.Option>
        );
      });
    temp.unshift(
      <Select.Option key={0} value={selectedPeserta?.id}>
        {selectedPeserta?.nama}
      </Select.Option>
    );
    return temp;
  }

  return (
    <div>
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              setSelectedPeserta(record);
              if (selectedPesertaDrop) {
                setShowModal(true);
              }
            },
          };
        }}
        columns={column}
        rowKey={(record) => record.id}
        dataSource={data.filter(
          (item) => item.status.toLowerCase() !== "kalah"
        )}
        pagination={{
          pageSize: 10,
          total: data.filter((item) => item.status.toLowerCase() !== "kalah")
            .length,
        }}
      />
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => updatePeserta()}
      >
        <div>
          <h3>Edit Peserta</h3>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Form.Item label="Nama">
              <Select
                onChange={(value) => {
                  const targetPeserta = data.find((item) => item.id === value);
                  setSelectedPesertaDrop(targetPeserta)
                }}
                value={selectedPesertaDrop?.id}
              >
                {getSelectOptions()}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
