import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Layout from "./layout/Layout";
import Bulanan from "./pages/bulanan/Bulanan";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import SettingBulanan from "./pages/settings/bulanan/SettingBulanan";
import PesertaBulanan from "./pages/settings/bulanan/PesertaBulanan";
import BulananLayoutSetting from "./pages/settings/bulanan/BulananLayoutSetting";
import HariRaya from "./pages/hari_raya/HariRaya";
import Spesial from "./pages/spesial/Spesial";
import Spin from "./pages/spin/Spin";
import { ILogin } from "./model/Users";
import { KeyToken } from "./utils/Constant";
import { useRecoilState } from "recoil";
import { TokenAtomState } from "./state/AtomToken";
import SpinLayoutSetting from "./pages/settings/spin/SpinLayoutSetting";
import SettingSpin from "./pages/settings/spin/SettingSpin";
import PesertaSpin from "./pages/settings/spin/PesertaSpin";
import HistorySpin from "./pages/settings/spin/HistorySpin";
import CustomSpin from "./pages/settings/spin/CustomSpin";
import Register from "./pages/register/Register";
import HistoryBulanan from "./pages/settings/bulanan/HistoryBulanan";
import CustomBulanan from "./pages/settings/bulanan/CustomBulanan";
import HariRayaBulanan from "./pages/settings/hari_raya/HariRayaBulanan";
import PesertaHariRaya from "./pages/settings/hari_raya/PesertaHariRaya";
import HariRayaLayoutSetting from "./pages/settings/hari_raya/HariRayaLayoutSetting";
import CustomHariRaya from "./pages/settings/hari_raya/CustomHariRaya";
import SettingHariRaya from "./pages/settings/hari_raya/SettingHariRaya";
import SpesialLayoutSetting from "./pages/settings/spesial/SpesialLayoutSetting";
import SettingSpesial from "./pages/settings/spesial/SettingSpesial";
import PesertaSpesial from "./pages/settings/spesial/PesertaSpesial";
import SpesialBulanan from "./pages/settings/spesial/SpesialBulanan";
import CustomSpesial from "./pages/settings/spesial/CustomSpesial";
import UserLayoutSetting from "./pages/settings/user/UserLayoutSetting";
import SettingUser from "./pages/settings/user/User";
import UserForm from "./pages/settings/user/UserForm";
import UserLog from "./pages/settings/user/UserLog";

function App() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tokenAtom, setTokenAtom] = useRecoilState(TokenAtomState);

  useEffect(() => {
    const temp = localStorage.getItem(KeyToken);
    if (temp) {
      setTokenAtom(JSON.parse(temp));
    } else {
      navigate('/login')
    }
    setLoading(true);
  }, []);

  const ProtectedRoute = ({
    token,
    children,
  }: {
    token: ILogin | null;
    children: JSX.Element;
  }) => {
    if (Object.keys(token as ILogin).length === 0) {
      return <Navigate to='/login' replace />;
    }
    return children;
  };

  if (!loading) {
    return null;
  }

  return (
    <div className='App'>
      <Routes>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/register' element={<Register />}></Route>
        <Route path='/bulanan' element={<Bulanan />} />
        <Route path='/hari-raya' element={<HariRaya />} />
        <Route path='/special' element={<Spesial />} />
        <Route
          path='/'
          element={
            <ProtectedRoute token={tokenAtom}>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path='/spin'
          element={
            <ProtectedRoute token={tokenAtom}>
              <Spin></Spin>
            </ProtectedRoute>
          }
        />
        <Route path='/setting-bulanan' element={<BulananLayoutSetting />}>
          <Route index element={<SettingBulanan />} />
          <Route path='/setting-bulanan/peserta' element={<PesertaBulanan />} />
          <Route path='/setting-bulanan/histori' element={<HistoryBulanan />} />
          <Route path='/setting-bulanan/pemenang' element={<CustomBulanan />} />
        </Route>
        <Route path='/setting-hari-raya' element={<HariRayaLayoutSetting />}>
          <Route index element={<SettingHariRaya />} />
          <Route path='/setting-hari-raya/peserta' element={<PesertaHariRaya />} />
          <Route path='/setting-hari-raya/histori' element={<HariRayaBulanan />} />
          <Route path='/setting-hari-raya/pemenang' element={<CustomHariRaya />} />
        </Route>
        <Route path='/setting-special' element={<SpesialLayoutSetting />}>
          <Route index element={<SettingSpesial />} />
          <Route path='/setting-special/peserta' element={<PesertaSpesial />} />
          <Route path='/setting-special/histori' element={<SpesialBulanan />} />
          <Route path='/setting-special/pemenang' element={<CustomSpesial />} />
        </Route>
        <Route path='/setting-spin' element={<SpinLayoutSetting />}>
          <Route index element={<SettingSpin />} />
          <Route path='/setting-spin/peserta' element={<PesertaSpin />} />
          <Route path='/setting-spin/histori' element={<HistorySpin />} />
          <Route path='/setting-spin/pemenang' element={<CustomSpin />} />
        </Route>
        <Route path='/setting-user' element={<UserLayoutSetting />}>
          <Route index element={<SettingUser />} />
          <Route path='/setting-user/log' element={<UserLog />} />
          <Route path='/setting-user/form' element={<UserForm />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
