import { ICreateSetting, IGetSetting } from "../model/Setting";
import { Request } from "../utils/Http";

export class ServiceSetting {
  public async AddSetting({
    id_undian,
    setting,
  }: {
    id_undian: number;
    setting: string;
  }): Promise<ICreateSetting> {
    const results = await Request({
      url: "/setting",
      method: "POST",
      data: {
        id_undian,
        setting,
      },
    });
    return results;
  }

  public async GetSetting({
    id_undian,
  }: {
    id_undian: number;
  }): Promise<IGetSetting> {
    const results = await Request({
      url: "/setting",
      method: "GET",
      params: {
        id_undian,
      },
    });
    return results;
  }
}
