import React from "react";
import { SettingPageId } from "../../../utils/Constant";
import PesertaComponent from "../../../components/ubo/peserta/Peserta";

export default function PesertaBulanan() {
  return (
    <div>
      <PesertaComponent id_undian={SettingPageId.bulanan}></PesertaComponent>
    </div>
  );
}
