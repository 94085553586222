import { IGetCreateLog } from "../model/Log";
import { ICreatePeserta, IGetPeserta } from "../model/Peserta";
import { Request } from "../utils/Http";

export class ServiceLog {
  public async AddLog({
    id_user,
    deskripsi
  }: {
    id_user: number;
    deskripsi: string;
  }): Promise<IGetCreateLog> {
    const results = await Request({
      url: "/log",
      method: "POST",
      showToast: false,
      data: {
        id_user,
        deskripsi,
      },
    });
    return results;
  }

  public async GetLog(): Promise<IGetCreateLog> {
    const results = await Request({
      url: "/log",
      method: "GET",
      params: {
        all: true
      }
    });
    return results;
  }
}
