import { Row, Col, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { IPeserta } from "../../model/Peserta";
import "./Generator.css";

interface IGenerator {
  data: IPeserta[];
  onWinnerSelected?(peserta: IPeserta): void;
  auto?: boolean;
  title: string;
  dataPer?: number;
  timing?: number;
  showHidden?: boolean;
}

const INIT_STATE = 0;
const START_STATE = 1;
const STOP_STATE = 2;
let isStop = false;

export default function Generator(props: IGenerator) {
  const refTimer = useRef<any>();
  const [randomText, setRandomText] = useState("");
  const [dataPeserta, setDataPeserta] = useState<IPeserta[]>([]);
  const [randomSeed, setRandomSeed] = useState<IPeserta[]>([]);
  const [timing, setTiming] = useState(3);
  const [state, setState] = useState(INIT_STATE);

  useEffect(() => {
    if (props.timing) {
      setTiming(props.timing / 100);
    }
    if (props.data) {
      setDataPeserta(props.data);
      setRandomSeed(props.data);
    }
  }, [props.data]);

  const startRandom = () => {
    // Continue Timer if exist
    // dont create any new event
    if (refTimer.current) {
      isStop = false;
      return;
    }
    const checkDrawed = dataPeserta.filter((item) => item.has_been_drawed);
    const checkLosePlayer = dataPeserta.filter(
      (item) => item.status.toLowerCase() !== "menang"
    );
    if (checkDrawed.length + checkLosePlayer.length === dataPeserta.length) {
      notification.open({
        message: "Semua Peserta Sudah Diundi",
        placement: "bottomRight",
        type: "success",
      });
      return;
    }
    setState(START_STATE);
    let numberOfWinner = dataPeserta.filter(
      (item) => item.status.toLowerCase() === "menang" && !item.has_been_drawed
    ).length;
    let counter = 0;
    let prevSelected: IPeserta = {} as IPeserta;
    let tempRandomSeed = randomSeed;
    let sleep = false;
    let counterPerPage = 0;

    refTimer.current = setInterval(async () => {
      if (isStop) {
        return;
      }
      if (numberOfWinner <= 0) {
        setTimeout(() => {
          //setRandomText("SELESAI");
          setState(STOP_STATE);
          clearInterval(refTimer.current);
        }, 1000);
        return;
      }
      if (counter === timing) {
        setState(STOP_STATE);
        numberOfWinner--;
        counter = 0;
        sleep = true;
        const winner = tempRandomSeed.filter(
          (item) =>
            item.status.toLowerCase() === "menang" && !item.has_been_drawed
        )[0];
        if (winner && winner.id) {
          tempRandomSeed = tempRandomSeed.filter(
            (item) => item.id !== winner.id
          );
          const tempUserName = props.showHidden
            ? winner.nama
            : `***${winner.nama.substring(5)}`;
          setRandomText(tempUserName);
          if (props.onWinnerSelected) {
            counterPerPage++;
            winner.has_been_drawed = true;
            setDataPeserta((prev) =>
              prev.map((item) => {
                if (item.id === winner.id) {
                  item.has_been_drawed = true;
                }
                return item;
              })
            );
            props.onWinnerSelected(winner);
            if (numberOfWinner <= 0) {
              setTimeout(() => {
                //setRandomText("SELESAI");
                clearInterval(refTimer.current);
              }, 1000);
              return;
            }
            if (props.auto === false) {
              setRandomSeed((prev) =>
                prev.map((item) => {
                  if (item.id === winner.id) {
                    item.has_been_drawed = true;
                  }
                  return item;
                })
              );
              clearInterval(refTimer.current);
              return;
            }
          }
          setTimeout(() => {
            sleep = false;
          }, 1000);
        }
      }
      if (!sleep) {
        setState(START_STATE);
        counter += 1;
        const tempSeed = prevSelected.id
          ? tempRandomSeed.filter(
              (item) => item.id !== prevSelected.id && !item.has_been_drawed
            )
          : tempRandomSeed;
        const index = Math.floor(Math.random() * tempSeed.length);
        if (prevSelected.id) {
          tempSeed.push(prevSelected);
        }
        prevSelected = tempSeed[index];
        if (randomText === "" || dataPeserta[index].nama !== randomText) {
          const tempUserName = props.showHidden
            ? prevSelected.nama
            : `***${prevSelected.nama.substring(5)}`;
          setRandomText(tempUserName);
        }
      }
    }, 100);
  };

  function stopRandom() {
    isStop = true;
    setState(INIT_STATE);
  }

  function setButtonColor() {
    const temp = {
      start: "linear-gradient(0deg, #fff200 0%, #f15a22 100%)",
      stop: "linear-gradient(0deg, #fff200 0%, #f15a22 100%)",
      textStart: "#640000",
      textStop: "#640000",
    };
    switch (state) {
      case START_STATE:
        temp.start =
          "linear-gradient(180deg, rgba(71,170,15,1) 0%, rgba(10,230,23,1) 35%, rgba(0,255,49,1) 100%)";
        temp.stop = "linear-gradient(0deg, #fff200 0%, #f15a22 100%)";
        temp.textStart = "white";
        break;
      case STOP_STATE:
        temp.start = "linear-gradient(0deg, #fff200 0%, #f15a22 100%)";
        temp.stop =
          "linear-gradient(180deg, rgba(199,48,73,1) 0%, rgba(199,48,48,1) 35%, rgba(255,0,0,1) 100%)";
        temp.textStop = "white";
        break;
    }
    return temp;
  }

  if (dataPeserta.length === 0) {
    return null;
  }

  return (
    <div className="slotContainer">
      <div className="wraper">
        <Row style={{ marginBottom: 10 }}>
          <Col xs={7}>
            <div className="live">
              <div className="liveDot animateFlicker"></div>
              LIVE NOW
            </div>
          </Col>
          <Col xs={17}>
            <div className="periodeText">{props.title}</div>
          </Col>
        </Row>
        <div className="slot">{randomText === "" ? "----" : randomText}</div>
        <div className="buttons">
          <div
            onClick={() => startRandom()}
            className="button ubo-button"
            style={{
              marginRight: 5,
              background: setButtonColor().start,
              color: setButtonColor().textStart,
            }}
          >
            START
          </div>
          <div
            onClick={() => stopRandom()}
            className="button ubo-button"
            style={{
              background: setButtonColor().stop,
              color: setButtonColor().textStop,
            }}
          >
            STOP
          </div>
        </div>
      </div>
    </div>
  );
}
