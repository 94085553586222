import React, { Fragment } from "react";
import { Outlet } from 'react-router-dom'
import Layout from "../../../layout/Layout";
import LayoutSetting, {
  ILayoutSetting,
} from "../../../layout/setting/LayoutSetting";

export default function HariRayaLayoutSetting() {
  const menu: ILayoutSetting = {
    title: "Setting Hari Raya",
    menus: [
      { id: 1, text: "Desain", to: "/setting-hari-raya" },
      { id: 2, text: "Peserta", to: "/setting-hari-raya/peserta" },
      { id: 3, text: "Histori", to: "/setting-hari-raya/histori" },
      { id: 4, text: "Custom Pemenang", to: "/setting-hari-raya/pemenang" },
      { id: 5, text: "View", to: "/hari-raya" },
    ],
  };

  return (
    <Fragment>
      <Layout hideOutlet={true}></Layout>
      <div style={{ display: "flex" }}>
        <LayoutSetting menus={menu.menus} title={menu.title}></LayoutSetting>
        <div style={{ padding: 20, width: "100%" }}>
          <Outlet></Outlet>
        </div>
      </div>
    </Fragment>
  );
}
