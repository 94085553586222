import axios from "axios";
import { KeyToken, Server } from "./Constant";
import { notification } from "antd";

interface IRequest {
  url: string;
  method: "POST" | "GET" | "PUT" | "DELETE" | "PATCH";
  data?: any;
  headers?: {};
  params?: {};
  useFormMultiPart?: boolean;
  showToast?: boolean;
}

export async function Request({
  url,
  method,
  data,
  headers,
  params,
  useFormMultiPart,
  showToast
}: IRequest) {
  let resultsResponse: any = {};
  let storage = localStorage.getItem(KeyToken);
  let urlServer =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? Server.dev
      : Server.prod;
  let requestParam: IRequest = {
    url: `${urlServer}${url}`,
    method: method,
  };
  if (storage) {
    let token = JSON.parse(storage).token;
    requestParam = {
      ...requestParam,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        ...headers,
      },
    };
  }
  if (data) {
    let temp = new FormData();
    if (useFormMultiPart) {
      Object.keys(data).forEach((item: any) => {
        temp.append(item, data[item]);
      });
    }
    requestParam = {
      ...requestParam,
      data: useFormMultiPart ? temp : data,
    };
  }
  if (params) {
    requestParam = {
      ...requestParam,
      params,
    };
  }
  try {
    const results = await axios(requestParam);
    if (
      showToast !== false &&
      method === "POST" ||
      method === "PUT" ||
      method === "DELETE" ||
      method === "PATCH"
    ) {
      notification.open({
        message: results.status === 200 ? "Sukses" : "Terjadi Kesalahan",
        duration: 2000,
        placement: "bottomRight",
        type: 'success'
      });
    }
    resultsResponse.data = results.data;
    resultsResponse.status = results.status;
  } catch (error: any) {
    if (error.response.statusText === "Unprocessable Entity") {
      const errorRaw = error.response.data.errors[0];
      resultsResponse.error = `${errorRaw.field} ${errorRaw.message}`;
      resultsResponse.status = error.response.status;
    }
    notification.open({
      message: "Terjadi Kesalahan",
      duration: 2000,
      placement: "bottomRight",
      type: 'error'
    });
  } finally {
    return resultsResponse;
  }
}
