import { ICreateHistoryImport, IGetHistoryImport, IHistoryImport } from "../model/History";
import { AddToLog } from "../utils/Helper";
import { Request } from "../utils/Http";
export class ServiceHistoryImport {
  public async add({
    id_undian,
    deskripsi,
  }: {
    id_undian: number;
    deskripsi: string;
  }): Promise<ICreateHistoryImport> {
    AddToLog(`Import Peserta dengan keterangan : ${deskripsi}`)
    const results = await Request({
      method: "POST",
      url: "/history/import",
      showToast: false,
      data: {
        id_undian,
        deskripsi
      },
    });
    return results;
  }

  public async get({
    id_undian
  }:{id_undian:number}): Promise<IGetHistoryImport> {
    const results = await Request({
      method: 'GET',
      url: '/history/import',
      params: {
        id_undian,
        all: true
      }
    })
    return results
  }

  public async delete({
    id
  }:{id:number}): Promise<IHistoryImport> {
    const results = await Request({
      method: 'DELETE',
      url: '/history/import',
      data: {
        id
      }
    })
    return results
  }

}
