import {
  Form,
  Input,
  Checkbox,
  Button,
  Card,
  notification,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ServiceLogin } from "../../service/ServiceLogin";

interface IRegister {
  id?: number;
  nama?: string;
  username?: string;
  email?: string;
  role?: number;
  status?: number
}

export default function Register(props: IRegister) {
  const navigate = useNavigate();
  const HttpLogin = new ServiceLogin();
  const [nama, setNama] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(2);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [status, setStatus] = useState(0)
  const { Option } = Select;

  useEffect(() => {
    if (props.nama && props.username && props.email && props.role && props.status) {
      setNama(props.nama);
      setUsername(props.username);
      setRole(props.role);
      setEmail(props.email);
      setStatus(props.status)
    }
  }, [props]);

  async function onSubmit() {
    if (nama && username && email && role !== 0) {
      if (props.id) {
        const results = await HttpLogin.UpdateUser({
          nama,
          email,
          role,
          status,
          id: props.id,
          username
        });
        return;
      } else if (password === confirmPassword) {
        const results = await HttpLogin.Register({
          nama,
          username,
          email,
          password,
          role,
        });
        return;
      }
    }
    notification.open({
      message: "Mohon Periksa Data Anda",
      placement: "bottomRight",
      type: "error",
    });
  }

  return (
    <div
      style={{
        padding: 20,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Card style={{ width: 500 }}>
        <Form
          labelCol={{ span: 9 }}
          name='basic'
          fields={[
            {
              name: ["nama_lengkap"],
              value: nama,
            },
            {
              name: ["username"],
              value: username,
            },
            {
              name: ["email"],
              value: email,
            },
            {
              name: ["role"],
              value: role && role === 1 ? "Admin" : "Operator",
            },
            {
              name: ["status"],
              value: status && status === 1 ? "Aktif" : "Non Aktif",
            },
          ]}
          onFinish={() => onSubmit()}
          autoComplete='off'>
          <Form.Item
            label='Nama Lengkap'
            name='nama_lengkap'
            rules={[{ required: true, message: "Mohon Mengisi Kolom Ini" }]}>
            <Input onChange={(e) => setNama(e.target.value)} />
          </Form.Item>
          <Form.Item
            label='Username'
            name='username'
            rules={[{ required: true, message: "Mohon Mengisi Kolom Ini" }]}>
            <Input
              disabled={props.id != undefined}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label='Email'
            name='email'
            rules={[{ required: true, message: "Mohon Mengisi Kolom Ini" }]}>
            <Input onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>
          {!props.id && (
            <>
              <Form.Item
                label='Password'
                name='password'
                rules={[
                  { required: true, message: "Mohon Mengisi Kolom Ini" },
                ]}>
                <Input.Password onChange={(e) => setPassword(e.target.value)} />
              </Form.Item>
              <Form.Item
                label='Konfirmasi Password'
                name='kpassword'
                rules={[
                  {
                    required: true,
                    message: "Mohon Mengisi Kolom Ini",
                  },
                ]}>
                <Input.Password
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            label='Akses User'
            name='role'
            rules={[
              {
                required: true,
                message: "Mohon Mengisi Kolom Ini",
              },
            ]}>
            <Select
              style={{ width: 120 }}
              onChange={(e: any) => {
                if (typeof e === "number") {
                  setRole(e);
                } else if (typeof e === "string") {
                  setRole(parseInt(e));
                }
              }}>
              <Option value='1'>Admin</Option>
              <Option value='2'>Operator</Option>
            </Select>
          </Form.Item>
          {props.id && (
            <Form.Item
              label='Status Aktif'
              name='status'>
              <Select
                style={{ width: 120 }}
                onChange={(e: any) => {
                  if (typeof e === "number") {
                    setStatus(e);
                  } else if (typeof e === "string") {
                    setStatus(parseInt(e));
                  }
                }}>
                <Option value='1'>Aktif</Option>
                <Option value='2'>Non Aktif</Option>
              </Select>
            </Form.Item>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
            }}>
            <Form.Item>
              <Button type='primary' htmlType='submit'>
                {props.id ? "Update User" : "Tambah User"}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </div>
  );
}
