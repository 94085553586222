import React, { useEffect, useState } from "react";
import { Button, Input, Table } from "antd";
import { ServicePeserta } from "../../../service/ServicePeserta";
import { IPeserta } from "../../../model/Peserta";
import { SettingPageId } from "../../../utils/Constant";
import Modal from "antd/lib/modal/Modal";
import { ServiceHistoryImport } from "../../../service/ServiceHistoryImport";
import { useJsonToCsv } from "react-json-csv";
import { FromStringToDateTime } from "../../../utils/Helper";
import PesertaComponent from "../../../components/ubo/peserta/Peserta";

export default function PesertaSpesial() {
  const ServiceHttp = new ServicePeserta();
  const HttpHistoryImport = new ServiceHistoryImport();
  const { saveAsCsv } = useJsonToCsv();
  const [data, setData] = useState<IPeserta[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [fileCSV, setFileCSV] = useState<any>();
  const [deskripsi, setDeskripsi] = useState("");
  const [column] = useState([
    {
      title: "No",
      dataIndex: "index_to_show",
      sorter: (a, b) => a.index_to_show - b.index_to_show,
    },
    {
      title: "Nama",
      dataIndex: "nama",
      sorter: (a, b) => {
        const temp = a.nama.localeCompare(b.nama);
        return parseInt(temp);
      },
    },
    {
      title: "Hadiah",
      dataIndex: "hadiah",
      sorter: (a, b) => {
        const temp = a.hadiah.localeCompare(b.hadiah);
        return parseInt(temp);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => {
        const temp = a.status.localeCompare(b.status);
        return parseInt(temp);
      },
    },
    {
      title: "Tanggal",
      dataIndex: "tanggal_undian",
      sorter: (a:any, b:any) => {
        const dateOne = new Date(a.tanggal_undian)
        const dateTwo = new Date(b.tanggal_undian)
        const result = (dateOne as any) - (dateTwo as any)
        return result
      },
    },
  ]);

  useEffect(() => {
    //getPeserta();
  }, []);

  async function getPeserta() {
    const results = await ServiceHttp.GetPeserta({
      id_undian: SettingPageId.special,
      all: true,
    });
    setData(
      results.data.map((item, index) => {
        item.index_to_show = index + 1;
        item.tanggal_to_show = FromStringToDateTime(item.tanggal_undian);
        return item;
      })
    );
  }

  async function onUpload() {
    if (fileCSV) {
      const resultsResultsImport = await HttpHistoryImport.add({
        id_undian: SettingPageId.special,
        deskripsi: deskripsi,
      });
      const results = await ServiceHttp.AddPeserta({
        id_import: resultsResultsImport.data.id,
        id_undian: SettingPageId.special,
        file: fileCSV,
      });
      if (results.status === 200) {
        window.location.reload();
      }
    }
  }

  async function exportToCsv() {
    saveAsCsv({
      data,
      fields: {
        pemenang_ke: "pemenang ke",
        nama: "nama",
        status: "status",
        hadiah_actual: "hadiah",
        tanggal_undian: "tanggal undian",
      },
      filename: `Peserta Special`,
    });
  }

  return (
    <div>
      <PesertaComponent id_undian={SettingPageId.special}></PesertaComponent>
    </div>
  );
}
