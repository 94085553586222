import { ICreateUser, IGetUser, ILogin } from "../model/Users";
import { AddToLog } from "../utils/Helper";
import { Request } from "../utils/Http";

export class ServiceLogin {
  public async login(username: string, password: string): Promise<ILogin> {
    const results = await Request({
      method: "POST",
      url: "/login",
      showToast: false,
      data: {
        username,
        password,
      },
    });
    return results;
  }

  public async Register({
    nama,
    username,
    email,
    password,
    role,
  }: {
    nama: string;
    username: string;
    email: string;
    password: string;
    role: number;
  }): Promise<ICreateUser> {
    AddToLog(`Menambahkan User dengan username:${username}`);
    const curDate = new Date();
    const results = await Request({
      method: "POST",
      url: "/users",
      data: {
        nama,
        email,
        username,
        password,
        status: 1,
        id_role: role,
        login: `${curDate.getFullYear()}-${
          curDate.getMonth() + 1
        }-${curDate.getDate()}`,
      },
    });
    return results;
  }

  public async GetUser(): Promise<IGetUser> {
    const results = await Request({
      url: "/users",
      method: "GET",
    });
    return results;
  }

  public async UpdateUser({
    id,
    nama,
    email,
    role,
    status,
    username,
  }: {
    id: number;
    nama: string;
    email: string;
    role: number;
    status: number;
    username: string;
  }): Promise<IGetUser> {
    AddToLog(`Melakukan Perubahan User dengan username:${username}`);
    const results = await Request({
      url: "/users",
      method: "PUT",
      data: {
        id,
        nama,
        email,
        id_role: role,
        status,
      },
    });
    return results;
  }

  public async forgetPassword(username: string): Promise<any> {
    const results = await Request({
      url: "/users/forget",
      method: "POST",
      showToast: false,
      data: {
        username,
      },
    });
    return results;
  }
}
